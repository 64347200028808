import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => {
  return (
    <Layout>
      <SEO title="About Us" />
      <div className="flex justify-center">
        <div className="container flex justify-center">
          <div className="my-20 flex">
            <div className="bg-white rounded-lg p-2 shadow-lg">
              <img src="https://scontent-atl3-1.xx.fbcdn.net/v/t1.0-9/121294289_10159052518259783_5784744393783863556_n.jpg?_nc_cat=101&ccb=2&_nc_sid=8bfeb9&_nc_ohc=k0_rGAl4XhIAX-DABpa&_nc_ht=scontent-atl3-1.xx&oh=78efbe6875c6c45dfecd290bacee8c52&oe=5FEC91C6" />
            </div>
            <div className="p-12 md:w-1/2 text-center font-bold text-2xl text-gray-600">
              <h2 className="text-3xl tracking-wide leading-9 mb-4 text-gray-800">
                About Us
              </h2>
              Lefty’s is a family friendly sports themed restaurant and bar.
              Celebrating over 20 years as a community favorite restaurant. Our
              wings have won many awards as the St. Lucie Best Wings!
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
